.sensor-nav {
  @media (min-width: 800px) {
    width: 300px;
    float: left;
  }

  a {
    display: block;
    border-radius: $border-radius;
    padding: 16px;
    margin-bottom: 12px;
    color: $grey;
    text-decoration: none;

    // Flex
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    &.active {
      background: white;
      box-shadow: 0 0 1px $blue-dark;
      color: $blue-dark;
      border-left: 4px solid $blue;
      margin-left: -4px;
    }

    &:hover {
      background: white;
      color: $blue-dark;
    }

    .sensor-nav-details {
      flex-grow: 2;

      .sensor-nav-name {
        font-weight: 700;
      }
    }
  
    .sensor-nav-level {
      float: left;
    }
  }
}