.loading-spinner, .loading-spinner::after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loading-spinner {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border: .9em solid rgba(150, 150, 150, 0.2);
  border-left-color: #ccc;
  transform: translateZ(0);
  animation: spinner 1s infinite linear;
  opacity: 1;
  transition: opacity 250ms ease;
}

@keyframes spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}