$blue-lighter: hsl(200, 12%, 95%);
$blue-light: hsl(200, 12%, 85%);
$blue: #3196C7;
$blue-dark: hsl(200, 25%, 25%);

$grey: hsl(200, 12%, 40%);
$black: hsl(200, 25%, 15%);

$green: #659157;
$orange: #F18F01;
$red: #dc493a;


$border-radius: 2px;

$font-size: 14px;
$font-size-lg: 18px;
$font-size-xl: 24px;
$font-size-xxl: 32px;

$spacing-md: 14px;
$spacing-lg: 18px;
$spacing-xl: 24px;
$spacing-xxl: 32px;