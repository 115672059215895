@import './variables';

@import './view-sensor';
@import './sensor-nav';
@import './header';
@import './loading-spinner';
@import './alert';

body {
  font-family: 'Open Sans', sans-serif;
  background: $blue-lighter;
  color: $blue-dark;
  font-size: $font-size;
  margin: 0;
}

.app-container {
  width: calc(100% - 24px);
  max-width: 1400px;
  margin: 0 auto;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.chart {
  margin-top: $spacing-xl;
}

/**
 * Layout
 */
.content {
  width: calc(100% - 324px);
  margin-left: 24px;
  float: left;
}

/**
 * Misc
 */
.all-sensors-button {
  background: white;
  border-radius: $border-radius;
  margin-bottom: $spacing-md;
  padding: 0;
  
  a {
    display: block;
    padding: $spacing-md $spacing-lg;
    color: $blue-dark;
    font-size: $font-size;
    font-weight: bold;
    text-decoration: none;
    line-height: 14px;
    vertical-align: middle;

    .menu-bars {
      float: left;
      margin-right: $spacing-lg / 2;

      div {
        width: 18px;
        height: 3px;
        background: $blue-dark;
        margin-bottom: 3px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}