$view-sensor-margin: 24px;
$view-sensor-padding: 16px;

.view-sensor {
  background: white;
  border-radius: $border-radius;
  box-shadow: 0 0 1px $blue-dark;
  margin-bottom: $view-sensor-margin;
  padding: $spacing-xl;

  .view-sensor-title-container {
    margin-bottom: $spacing-xl;

    .view-sensor-title {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .view-sensor-name {
        flex-grow: 2;
        font-size: $font-size-xxl;
        font-weight: 400;
        margin: 0;
      }

      .view-sensor-level {
        font-size: $font-size-lg;
        font-weight: 700;
        border-radius: 8px;
        background: $blue;
        text-align: center;
        color: white;
        padding: $spacing-md $spacing-lg;
      }
    }

    .view-sensor-serial {
      color: $grey;
    }
  }
}