$navbar-height: 60px;
$logo-height: 40px;

.header {
  width: 100%;
  height: $navbar-height;
  background: $black;
  margin-bottom: $spacing-xl;

  .logo {
    float: left;
    height: $logo-height;
    padding: ($navbar-height - $logo-height) / 2;
  }

  .brand-name {
    float: left;
    line-height: $logo-height;
    padding: ($navbar-height - $logo-height) / 2;
    vertical-align: middle;
    color: white;
    font-size: $font-size-lg;

    .org-name {
      font-weight: 300;
    }
  }

  .waterwatch-link {
    float: right;
    line-height: $logo-height;
    padding: ($navbar-height - $logo-height) / 2;
    vertical-align: middle;
    color: white;
  }
}